import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import Grid from '@material-ui/core/Grid'
import MaterialButton from '@material-ui/core/Button'

const Button = styled(MaterialButton)`
    && {
        height: 60px;
        border-radius: 30px;
        width: 100%;
    }
`

const goals = [
    {
        name: 'urgent',
        label: 'Régler une situation urgente',
    },
    {
        name: 'serious',
        label: 'Répondre à un besoin réel et sérieux, mais non urgent',
    },
    {
        name: 'explore',
        label: 'Explorer simplement mes options juridiques',
    },
]

const Goals = ({ handleSelect, selectedType }) => {
    const { t } = useTranslation()
    return (
        <Grid container spacing={2} style={{ maxWidth: '700px' }}>
            {goals.map((item) => {
                return (
                    <Grid key={item.name} item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color={
                                selectedType === item.name
                                    ? 'secondary'
                                    : 'primary'
                            }
                            onClick={() => handleSelect(item.name)}
                        >
                            {t(item.label)}
                        </Button>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default Goals
